import React from "react"
import Mobber from "../images/mobber.png"
import Cialo from "../images/cialo.png"
import Adpilot from "../images/adpilot.png"

const FeaturedBlog = () => {
  return (
    <div className="max-w-7xl mx-auto px-8 mt-10">
      <h1 className="text-white font-poppins font-bold text-6xl xxs:text-lg xs:text-xl sm:text-xl lg:text-6xl">
        Produtos <br></br> digitais&nbsp;
        <span class="text-gradient palette-red">inteligentes</span>
      </h1>
      <p className="text-white text-lg mt-5 opacity-50 w-3/4 xxs:w-full lg:w-3/4">
        Nossos produtos digitais idealizados para potencializar vendas e
        auxiliar na tomada de decisão de maneira inteligente.
      </p>
      <div className="grid grid-cols-3 mt-10 gap-2 xxs:grid-cols-1 lg:grid-cols-3">
        
      <div className="w-1/3 highlights palette-redpalette-red p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-5/6">
        <h1 className="text-white text-4xl">mobber.</h1>
        <p className="mt-10 text-white opacity-50">
          A rede social para compras e descontos.{" "}
        </p>
      </div>

      <div className="w-1/3 highlights p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-5/6">
        <h1 className="text-white text-4xl">Cialo</h1>
        <p className="mt-10 text-white opacity-50">
          Descubra o que seus clientes realmente querem com os insights do Cialo.{" "}
        </p>
      </div>

      <div className="w-1/3 highlights p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-5/6">
        <h1 className="text-white text-4xl">adPilot</h1>
        <p className="mt-10 text-white opacity-50">
        Menos tentativa e erro. Mais performance. O adPilot cuida do seu Google Ads para você.{" "}
        </p>
      </div>
        
        
      </div>
    </div>
  )
}

export default FeaturedBlog
