import React from "react"
import Input from "../Atoms/input"
import Button from "../Atoms/button"
import Fade from "react-reveal/Fade"

// Assets
import HeaderImage from "../../images/header-main.jpg"

const Header = () => {
  return (
    <div className="max-w-7xl mx-auto px-8">
      <Fade>
        <div>
          <h2 className="text-white font-poppins opacity-70 font-semibold mt-20 text-xs xxs:text-xs xs:text-xs sm:text-xs md:text-sm lg:text-2xl">
            Impulsione suas vendas
          </h2>

          <h1 className="text-white font-poppins font-semibold text-2xl xxs:text-2xl xs:text-2xl sm:text-lg md:text-2xl lg:text-4xl xl:text-7xl 2xl:text-8xl">
            com um<br></br>{" "}
            <span class="text-gradient palette-red">click inteligente.</span>
          </h1>
        </div>
      </Fade>


      <Fade bottom>

        <form action="https://clickmarketdigital.us11.list-manage.com/subscribe/post?u=542f7748ce6f09b00a6859d16&amp;id=aae1f4db6c&amp;f_id=00f417e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self" novalidate="" className="mt-10 xxs:flex xxs:flex-row sm:block">
          <Input  type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value="" tabindex="-1"  placeholder="email."></Input>
       
          <Button
            colorClass="palette-red "
            title="Solicitar contato"
            textColor="text-white"
            marginClass="ml-5"
          ></Button>
        </form>
      </Fade>
      <div className="max-w-7xl mx-auto mt-10 px-4">
        <div className="w-full h-auto overflow-hidden rounded-xl">
          <img className="w-full h-full" src={HeaderImage} alt="Header"></img>
        </div>
      </div>
    </div>
  )
}

export default Header
